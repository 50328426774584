import { useReducer } from "react";
import {
  Container,
  Divider,
  Typography,
  Stack,
  Box,
  Grid,
  Button,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  Modal,
  Skeleton,
  Avatar,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import axios from "../utils/axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";
import { bgBlur } from "../utils/cssStyles";

const reducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        fareParameter: action.payload,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
  }
};

const ViewFare = () => {
  const [{ loading, fareParameter, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });
  const { token } = useSelector((state) => state.auth);
  const [openEdit, setOpenEdit] = useState(false);

  const params = useParams();

  const [baseFare, setBaseFare] = useState("");
  const [fareCut, setFareCut] = useState("");
  const [farePerKm, setfarePerKm] = useState("");
  const [feed, setFeed] = useState("");

  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: "30rem",
    overflow: "scroll",
    borderRadius: "0.6rem",
  };


  const handleEditClose = () => setOpenEdit(false);

  const handleEditOpen = () => {
    setOpenEdit(true);
  };
  // http://3.239.229.120:5000/api/fare/fare-parameter/:id

  const getFare = async (id) => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const { data } = await axios.get(`/api/fare/fare-parameter/${params?.id}`, {
        headers: { Authorization: token },
      });

      setBaseFare(data?.data?.fareParameter?.baseFare);
      setfarePerKm(data?.data?.fareParameter?.farePerKm);
      setFeed(data?.data?.fareParameter?.feed)
      setFareCut(data?.data?.fareParameter?.fareCut||"");
      dispatch({ type: "FETCH_SUCCESS", payload: data?.data?.fareParameter });
    } catch (error) {
      // console.log(error);
      dispatch({ type: "FETCH_FAIL", payload: error });
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    console.log(fareCut);
    try {
      const { data } = await axios.put(
        `/api/fare/fare-parameters/${params?.id}`,
        {
          baseFare, feed, farePerKm,fareCut
        },
        {
          headers: { Authorization: token },
        }
      );

      // console.log("updated ride ", data);
      toast.success("Ride details updated!", toastOptions);
      getFare();
    } catch (error) {
      // console.log(error);
      toast.error("Something went wrong. Please try again later", toastOptions);
    }

    setOpenEdit(false);
  };

  useEffect(() => {
    getFare();
  }, []);

  // console.log(fareParameter);

  return (
    <>
      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Helmet>
          <title> View Fares | UR DRIVER </title>
        </Helmet>

        <Container>
          {loading ? (
            <Skeleton variant="text" width={100} animation="wave">
              <Avatar />
            </Skeleton>
          ) : (
            <>
              <Typography
                variant="h5"
                component="span"
                sx={{ color: "#276BC7" }}
              >
                {capitalizeFirstLetter(fareParameter?.vehicle.type) + " " + capitalizeFirstLetter(fareParameter?.vehicle.wheels) + " Wheeler"} Details
              </Typography>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                // className="w-6 h-6"
                style={{
                  width: "2rem",
                  marginLeft: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => handleEditOpen()}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            </>
          )}
          <Divider />
          <Box
            component="div"
            sx={{
              border: "1px solid #CCCCFF",
              borderRadius: "0.6rem",
              width: "100%",
              height: "100%",
              p: 2,
            }}
          >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              direction={{ sm: "column", md: "row" }}
            >
              {loading ? (
                <Skeleton animation="wave" width={500} height={300} />
              ) : (
                <>
                  <Grid item xs={12} md={2}>
                    <div>
                      <div>
                        <b>Base Fare</b>
                      </div>
                      <p style={{ color: "#6CA0DC" }}>{fareParameter?.baseFare}</p>
                    </div>
                    <div>
                      <div>
                        <b>Fare Per KM</b>
                      </div>
                      <p style={{ color: "#6CA0DC" }}>
                        {fareParameter?.farePerKm}
                      </p>
                    </div>
                    <div>
                      <div>
                        <b>fareCut</b>
                      </div>
                      <p style={{ color: "#6CA0DC" }}>
                        {fareParameter?.fareCut||"Null"}
                      </p>
                    </div>
                    <div>
                      <div>
                        <b>Feed</b>
                      </div>
                      <p style={{ color: "#6CA0DC" }}>{fareParameter?.feed}</p>
                    </div>

                    <div>
                      <div>
                        <b>Updated At</b>
                      </div>
                      <p style={{ color: "#6CA0DC" }}>
                        {moment(fareParameter?.updatedAt).utc().format("MMMM DD, YYYY")}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <div>
                      <div>
                        <b>Vehicle Type</b>
                      </div>
                      <p style={{ color: "#6CA0DC" }}>{fareParameter?.vehicle?.type}</p>
                    </div>

                    <div>
                      <div>
                        <b>Vehicle Wheels</b>
                      </div>
                      <p style={{ color: "#6CA0DC" }}>
                        {fareParameter?.vehicle?.wheels}
                      </p>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>

          </Box>

          <Modal
            open={openEdit}
            onClose={handleEditClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit Fare Parameter
              </Typography>
              <Stack direction="column" spacing={3}>
                <form onSubmit={handleEditSubmit}>
                  <div style={{ margin: "2rem 0rem" }}>
                    <TextField
                      fullWidth
                      // disabled
                      name="baseFare"
                      id="baseFare"
                      label="Base Fare"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setBaseFare(e.target.value)}
                      value={baseFare}
                    />
                  </div>

                  <div style={{ margin: "2rem 0rem" }}>
                    <TextField
                      fullWidth
                      // disabled
                      name="farePerKm"
                      id="farePerKm"
                      label="Fare Per Km"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setfarePerKm(e.target.value)}
                      value={farePerKm}
                    />
                  </div>
                  <div style={{ margin: "2rem 0rem" }}>
                    <TextField
                      fullWidth
                      // disabled
                      name="fareCut"
                      id="fareCut"
                      label="fareCut"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFareCut(e.target.value)}
                      value={fareCut}
                    />
                  </div>

                  <div style={{ margin: "2rem 0rem" }}>
                    <TextField
                      fullWidth
                      // disabled
                      name="feed"
                      id="feed"
                      label="Feed"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFeed(e.target.value)}
                      value={feed}
                    />
                  </div>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="medium"
                  >
                    Update
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    size="medium"
                    onClick={handleEditClose}
                    style={{ marginLeft: "1rem" }}
                  >
                    Cancel
                  </Button>
                </form>
              </Stack>
            </Box>
          </Modal>
        </Container>
      </motion.div>
      <ToastContainer />
    </>
  );
};

export default ViewFare;
