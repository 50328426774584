/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { useEffect, useState, useReducer } from "react";
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Modal,
    Box,
    Skeleton,
} from "@mui/material";
// components
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// mock
import axios from "../utils/axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: "baseFare", label: "Base Fare", alignRight: false },
    { id: "farePerKm", label: "Fare per KM", alignRight: false },
    { id: "fareCut", label: "fareCut", alignRight: false },
    { id: "feed", label: "Feed", alignRight: false },
    { id: "vehicleType", label: "Vehicle Type", alignRight: false },
    { id: "vehicleWheels", label: "Vehicle Wheels", alignRight: false },
    { id: "actions", label: "Actions", alignRight: false },
];

// ----------------------------------------------------------------------

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return { ...state, loading: true };
        case "FETCH_SUCCESS":
            return {
                ...state,
                usersList: action.payload,
                loading: false,
            };
        case "FETCH_FAIL":
            return { ...state, loading: false, error: action.payload };
    }
};

export default function FarePage() {
    const [{ loading, usersList, error }, dispatch] = useReducer(reducer, {
        loading: true,
        error: "",
    });
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.auth);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteId, setDeleteId] = useState();

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState("asc");

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState("name");

    const [filterName, setFilterName] = useState("");
    const [filterStatus, setFilterStatus] = useState("");

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleFilterByStatus = (event) => {
        setPage(0);
        setFilterStatus(event.target.value);
    };

    const toastOptions = {
        position: "bottom-center",
        autoClose: 3000,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    };

    const fetchTrips = async () => {
        dispatch({ type: "FETCH_REQUEST" });
        try {
            const { data } = await axios.get(
                `/api/fare/fare-parameters`,
                {
                    headers: { Authorization: token },
                }
            );

            console.log("trips ", data);
            // setUsersList(data?.data?.trips);
            dispatch({ type: "FETCH_SUCCESS", payload: data?.data?.fareParameters });
        } catch (error) {
            // console.log(error);
            dispatch({ type: "FETCH_FAIL", payload: error });
        }
    };

    useEffect(() => {
        fetchTrips();
    }, [filterStatus]);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = usersList?.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, _id) => {
        const selectedIndex = selected.indexOf(_id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList?.length) : 0;

    let filteredUsers = usersList;


    const isNotFound = !filteredUsers?.length && !!filterName;

    return (
        <>
            <motion.div
                initial={{ x: "-100%" }}
                animate={{ x: "0%" }}
                exit={{ x: "100%" }}
                transition={{ duration: 0.75, ease: "easeInOut" }}
            >
                <Helmet>
                    <title> Fares | UR DRIVER </title>
                </Helmet>

                <Container>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={5}
                    >
                        <Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                                // for new-zealdn theme
                                color: "#276BC7",
                                // color: "#7E4EFC",
                            }}
                        >
                            Fares
                        </Typography>
                    </Stack>

                    <Card
                        style={
                            {
                                // for new-zealnd theme
                                // backgroundColor: "#26303C",
                                // backgroundColor: "#EBEEF7",
                            }
                        }
                    >
                        {/* <UserListToolbar
                            numSelected={selected.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            filterStatus={filterStatus}
                            onFilterStatus={handleFilterByStatus}
                            fetchTrips={fetchTrips}
                        /> */}

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                {loading ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        <Skeleton variant="text" animation="wave" width={500}>
                                            <Avatar />
                                        </Skeleton>
                                        <Skeleton variant="text" animation="wave" width={500}>
                                            <Avatar />
                                        </Skeleton>
                                        <Skeleton variant="text" animation="wave" width={500}>
                                            <Avatar />
                                        </Skeleton>
                                    </div>
                                ) : (
                                    <Table>
                                        <UserListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={TABLE_HEAD}
                                            rowCount={usersList?.length}
                                            numSelected={selected.length}
                                            onRequestSort={handleRequestSort}
                                            onSelectAllClick={handleSelectAllClick}
                                        />
                                        <TableBody>
                                            {filteredUsers
                                                .slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                .map((row) => {
                                                    const {
                                                        _id,
                                                        baseFare,
                                                        farePerKm,
                                                        feed,
                                                        vehicle,
                                                        fareCut
                                                    } = row;
                                                    const selectedUser = selected.indexOf(_id) !== -1;

                                                    return (
                                                        <>
                                                            <TableRow
                                                                hover
                                                                key={_id}
                                                                tabIndex={-1}
                                                                role="checkbox"
                                                                selected={selectedUser}
                                                            >
                                                                <TableCell
                                                                    padding="checkbox"
                                                                    sx={
                                                                        {
                                                                            // for new-zealdn theme
                                                                            // color: "#CCCCFF",
                                                                            // borderColor: "#89CFF0",
                                                                            // color: "text.secondary",
                                                                            // borderColor: "#E3D8F4",
                                                                        }
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        checked={selectedUser}
                                                                        onChange={(event) =>
                                                                            handleClick(event, _id)
                                                                        }
                                                                    />
                                                                </TableCell>

                                                                <TableCell
                                                                    sx={
                                                                        {
                                                                            // for new-zealdn theme
                                                                            // color: "#CCCCFF",
                                                                            // borderColor: "#89CFF0",
                                                                            // color: "text.secondary",
                                                                            // borderColor: "#E3D8F4",
                                                                        }
                                                                    }
                                                                    component="th"
                                                                    scope="row"
                                                                    padding="none"
                                                                >
                                                                    <Stack
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        spacing={2}
                                                                    >

                                                                        <Typography variant="subtitle2" noWrap>
                                                                            {baseFare}
                                                                        </Typography>
                                                                    </Stack>
                                                                </TableCell>

                                                                <TableCell
                                                                    sx={
                                                                        {
                                                                            // for new-zealdn theme
                                                                            // color: "#CCCCFF",
                                                                            // borderColor: "#89CFF0",
                                                                            // color: "text.secondary",
                                                                            // borderColor: "#E3D8F4",
                                                                        }
                                                                    }
                                                                    align="left"
                                                                >
                                                                    {farePerKm}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={
                                                                        {
                                                                            // for new-zealdn theme
                                                                            // color: "#CCCCFF",
                                                                            // borderColor: "#89CFF0",
                                                                            // color: "text.secondary",
                                                                            // borderColor: "#E3D8F4",
                                                                        }
                                                                    }
                                                                    align="left"
                                                                >
                                                                    {fareCut?fareCut:"Null"}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={
                                                                        {
                                                                            // for new-zealdn theme
                                                                            // color: "#CCCCFF",
                                                                            // borderColor: "#89CFF0",
                                                                            // color: "text.secondary",
                                                                            // borderColor: "#E3D8F4",
                                                                        }
                                                                    }
                                                                    align="left"
                                                                >
                                                                    {feed}
                                                              
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={
                                                                        {
                                                                            // for new-zealdn theme
                                                                            // color: "#CCCCFF",
                                                                            // borderColor: "#89CFF0",
                                                                            // color: "text.secondary",
                                                                            // borderColor: "#E3D8F4",
                                                                        }
                                                                    }
                                                                    align="left"
                                                                >
                                                                    {vehicle.type}
                                                       
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={
                                                                        {
                                                                            // for new-zealdn theme
                                                                            // color: "#CCCCFF",
                                                                            // borderColor: "#89CFF0",
                                                                            // color: "text.secondary",
                                                                            // borderColor: "#E3D8F4",
                                                                        }
                                                                    }
                                                                    align="left"
                                                                >
                                                                    {vehicle.wheels}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={
                                                                        {
                                                                            // borderColor: "#89CFF0",
                                                                        }
                                                                    }
                                                                    align="left"
                                                                >
                                                                    <div style={{ display: "flex" }}>
                                                                        <div
                                                                            style={{
                                                                                margin: "0rem 1rem",
                                                                                width: "1rem",
                                                                                color: "#6CA0DC",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() =>
                                                                                navigate(`/dashboard/fare/view/${_id}`)
                                                                            }
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth={1.5}
                                                                                stroke="currentColor"
                                                                            // className="w-6 h-6"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                                                                />
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })}

                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>

                                        {isNotFound && (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        align="center"
                                                        colSpan={6}
                                                        sx={{ py: 3, border: "none" }}
                                                    >
                                                        <Paper
                                                            sx={{
                                                                textAlign: "center",
                                                                backgroundColor: "transparent",
                                                            }}
                                                        >
                                                            <Typography variant="h6" paragraph>
                                                                Not found
                                                            </Typography>

                                                            <Typography variant="body2">
                                                                No results found for &nbsp;
                                                                <strong>&quot;{filterName}&quot;</strong>.
                                                                <br /> Try checking for typos or using complete
                                                                words.
                                                            </Typography>
                                                        </Paper>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                )}
                            </TableContainer>
                        </Scrollbar>
                    </Card>
                </Container>
            </motion.div>
            <ToastContainer />
        </>
    );
}
