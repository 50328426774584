import React from 'react';
import { styled } from '@mui/material/styles';
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet-async';
import {
    Typography,
    Container,
    FilledInput,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    CircularProgress,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../components/features/loadingSlice';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
//import axios from "../utils/axios";


const AddUser = () => {

    const { token } = useSelector((state) => state.auth);
    const { isLoading } = useSelector((state) => state.loading);
    
    console.log("token: ",token);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedFile, setSelectedFile] = React.useState(null);


     
    const VehicleType = [
        'hatchback', 'sedan', 'suv', 'muv', 'street', 'electric', 'regular'
    ];

    function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const [formdata, setFormdata] = React.useState({
        email: '',
        name: '',
        sex: '',
        city: '',
        phone: '',
        device_token: 'sentfromweb',
        account_type: 'user',
        age: '',
        // license: {
        //     image: selectedFile,
        //     license_no: ''
        // },
        // vehicle: {
        //     wheels: '',
        //     type: '',
        //     information: {
        //         brand: '',
        //         model: '',
        //         registration_no: ''
        //     }
        // }
    })

    // email,
    // name,
    // sex,
    // city,
    // phone,
    // device_token
    // account_type: "driver" or"user",
    // age,
    //license: {
    //     image: {

    //     },

    //     license_no: { type: String }

    // }, vehicle: {
    //     wheels('two' or 'four'), type, information: { brand, model, registration_no }
    // }

    const toastOptions = {
        position: "bottom-center",
        autoClose: 3000,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      };

    const handleSubmit = async(e)=>{
        e.preventDefault();

        try {
            dispatch(showLoading());

            console.log(formdata)

            const response = await axios.post(
                'http://3.239.229.120:5000/api/admin/user',
                formdata,
                {
                    headers: {
                        Authorization:  token ,
                        
                    },
                }
            );
            dispatch(hideLoading());
            
              console.log(response);
                    
            console.log("Created")
    
           
            navigate('/dashboard/user')
            toast.success("User Created!", toastOptions);

        } catch (error) {
            dispatch(hideLoading());
         //   toast.error(error?.response?.data?.error?.message || error?.message, toastOptions);
            toast.error("Something went wrong please try again later", toastOptions);
            console.log(error);
        }
    }


    const handleFileChange = (event) => {
        // Update state with the selected file
        setSelectedFile(event.target.files[0]);
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    return (
        <>
            <motion.div
                initial={{ x: "-100%" }}
                animate={{ x: "0%" }}
                exit={{ x: "100%" }}
                transition={{ duration: 0.75, ease: "easeInOut" }}
            >
                <Helmet>
                    <title> Add User | UR DRIVER </title>
                </Helmet>
                <Container>
                    <Typography
                        variant="h5"
                        component="span"
                        sx={{ color: "#276BC7" }}
                    >
                        Add User
                    </Typography>
                    <form onSubmit={handleSubmit}> 
                    <Box
                        component="div"
                        sx={{
                            border: "1px solid #CCCCFF",
                            borderRadius: "0.6rem",
                            width: "100%",
                            height: "100%",
                            p: 2,
                            gap: '20px'
                        }}
                    >
                       
                        <Box
                            component="div"
                            sx={{
                                border: "1px solid #CCCCFF",
                                borderRadius: "0.6rem",
                                width: "100%",
                                height: "100%",
                                p: 2,
                                gap: '20px'
                            }}
                        >
                           
                            <FormControl variant="filled" sx={{ margin: 1 }}>
                                <InputLabel htmlFor="component-filled">Email</InputLabel>
                                <FilledInput required id="component-filled" value={formdata.email} onChange={(e) => { setFormdata({ ...formdata, email: e.target.value }) }} placeholder='Email address' />
                            </FormControl>
                            <FormControl variant="filled" sx={{ margin: 1 }}>
                                <InputLabel htmlFor="component-filled">Name</InputLabel>
                                <FilledInput required id="component-filled" value={formdata.name} onChange={(e) => { setFormdata({ ...formdata, name: e.target.value }) }} placeholder='Enter Your Name' />
                            </FormControl>
                            <FormControl variant="filled" sx={{ margin: 1 }}>
                                <InputLabel htmlFor="component-filled">Age</InputLabel>
                                <FilledInput required id="component-filled" value={formdata.age} onChange={(e) => { setFormdata({ ...formdata, age: e.target.value }) }} placeholder='Enter Your Age' />
                            </FormControl>
                            <FormControl variant="filled" sx={{ margin: 1, width: 100 }}>
                                <InputLabel id="demo-simple-select-label">Sex</InputLabel>
                                <Select
                                required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formdata.sex}
                                    onChange={(e) => { setFormdata({ ...formdata, sex: e.target.value }) }}
                                    label="Sex"
                                >
                                    <MenuItem value={"Male"}>M</MenuItem>
                                    <MenuItem value={"Female"}>F</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="filled" sx={{ margin: 1 }}>
                                <InputLabel htmlFor="component-filled">City</InputLabel>
                                <FilledInput required id="component-filled" value={formdata.city} onChange={(e) => { setFormdata({ ...formdata, city: e.target.value }) }} placeholder='Enter Your City' />
                            </FormControl>
                            <FormControl variant="filled" sx={{ margin: 1 }}>
                                <InputLabel htmlFor="component-filled">Phone</InputLabel>
                                <FilledInput required id="component-filled" value={formdata.phone} onChange={(e) => { setFormdata({ ...formdata, phone: e.target.value }) }} placeholder='Enter Phone Number' />
                            </FormControl>
                            
                        </Box>
                        <Button
            sx={{ margin: 1 }}
            type="submit"
            variant="contained"
            disabled={isLoading}
            onClick={handleSubmit}
        >
            {isLoading ? (
                <CircularProgress size={24} sx={{ color: 'blue' }} />
            ) : (
                'Submit'
            )}
        </Button>
                        {/* <Button
                    sx={{margin: 1}}
                    type='submit'
                    variant="contained">
                        {isLoading?
                        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />:
                        
                        "Submit"} </Button> */}
                    </Box>
                    
                    </form>
                </Container>
            </motion.div>
             {/*onClick={() => navigate('/dashboard/driver/addDriver')} */}
             <ToastContainer />
        </>
    )
}

export default AddUser