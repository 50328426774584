import ReactDOM from 'react-dom';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import store from './components/features/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// ----------------------------------------------------------------------

// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
  
// );

ReactDOM.render(<>
  <Provider store={store}>
    <App />
  </Provider>
</>, document.getElementById('root'))

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
